<spot-drop-modal
  [open]="dropModalOpen"
  (closed)="close()"
  (keydown)="onKeydown($event)"
  alignment="bottom-left"
  class="op-project-list-modal"
>
  <button
    id="projects-menu"
    accesskey="5"
    aria-haspopup="true"
    class="op-app-menu--item-action"
    type="button"
    slot="trigger"
    (click)="toggleDropModal()"
  >
    <span
      class="op-app-menu--item-title ellipsis"
      [textContent]="currentProjectName()"
    ></span>
    <i class="op-app-menu--item-dropdown-indicator button--dropdown-indicator"></i>
  </button>

  <ng-container slot="body">
    <div class="op-project-list-modal--header">
      <h1
        class="op-project-list-modal--title"
        [textContent]="text.project.plural"
      ></h1>
    </div>
    <div
      class="op-project-list-modal--body spot-container"
    >
      <spot-text-field
        [placeholder]="text.search_placeholder"
        [(ngModel)]="searchableProjectListService.searchText"
        [ngModelOptions]="{standalone: true}"
        data-qa-selector="op-project-menu-autocomplete--search"
        data-list-focus-catcher-container="true"
        data-modal-focus-catcher-container="true"
      >
        <span
          slot="before"
          class="spot-icon spot-icon_search"
        ></span>
      </spot-text-field>

      <ng-container *ngIf="(loading$ | async) === false; else loadingTemplate">
        <ng-container *ngIf="(projects$ | async) as projects">
          <ul
            *ngIf="projects.length > 0; else noResultsTemplate"
            op-project-list
            [projects]="projects"
            [searchText]="searchableProjectListService.searchText"
            [multiSelect]="false"
            [root]="true"
            data-list-root="true"
            data-qa-selector="op-project-menu-autocomplete--list"
          ></ul>

          <ng-template #noResultsTemplate>
            <span class="op-project-list-modal--no-results">
              {{text.no_results}}
            </span>
          </ng-template>
        </ng-container>
      </ng-container>

      <div class="spot-action-bar">
        <div class="spot-action-bar--right">
          <a
            class="button button_no-margin spot-action-bar--action"
            [href]="allProjectsPath()"
          >
            <i class="button--icon spot-icon spot-icon_unordered-list"></i>
            <span
              class="button--text"
              [textContent]="text.project.list"
            ></span>
          </a>
          <a
            *ngIf="canCreateNewProjects$ | async"
            class="button button_no-margin -alt-highlight spot-action-bar--action"
            [href]="newProjectPath()"
          >
            <i class="button--icon spot-icon spot-icon_add"></i>
            <span
              class="button--text"
              [textContent]="text.project.singular"
            ></span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #loadingTemplate>
    <op-content-loader
      class="op-project-list-modal--loading"
      viewBox="0 0 200 140"
    >
      <svg:rect x="0" width="100%" height="12" rx="1" />

      <svg:rect x="10" y="16" width="100%" height="12" rx="1" />

      <svg:rect x="10" y="32" width="100%" height="12" rx="1" />

      <svg:rect x="20" y="48" width="100%" height="12" rx="1" />

      <svg:rect x="20" y="64" width="100%" height="12" rx="1" />

      <svg:rect x="0" y="80" width="100%" height="12" rx="1" />

      <svg:rect x="10" y="96" width="100%" height="12" rx="1" />

      <svg:rect x="10" y="112" width="100%" height="12" rx="1" />

      <svg:rect x="0" y="128" width="100%" height="12" rx="1" />
    </op-content-loader>
  </ng-template>
</spot-drop-modal>
